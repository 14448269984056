<template>
    <section class="content">
        <header class="content__title">
            <h1>Daily Power Devotional</h1>
            <small>A list of news spanning various categories</small>
            <div class="actions">
                <button class="btn btn-primary" @click="add">
                    <i class="zmdi zmdi-plus"></i>
                    Add Lesson
                </button>
            </div>
        </header>
        <div class="card">
            <div class="card-header bg-brown">
                <select class="devotional-year" v-model="year" @change="setYear">
                    <option v-for="year in years" :key="year">{{ year }}</option>
                </select>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <ul class="devotional-sidebar">
                        <li @click="home">Months</li>
                        <li v-for="(month, i) in months" :key="i" @click="loadByMonth(month, i)" :class="isSelectMonth(month)">
                            {{ $moment(month + 1, "M").format("MMMM") }}
                        </li>
                    </ul>
                </div>
                <div class="col-md-10">
                    <router-view :key="$route.fullPath" />
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </section>
</template>

<script>
import dayjs from 'dayjs'
export default {
    data(){
        return {
            months: Array.from(Array(12).keys()),
            year: this.$store.state.devotionalYear,
            isSelectMontha: "selected-month",
        }
    },
    methods: {
        async loadByMonth(month){
            this.$router.push("/dailypower/m/" + this.$moment(month + 1, "M").format("MMMM"))
        },
        add(){
            this.$router.push("/dailypower/add")
        },
        home(){
            this.$router.push("/dailypower")
        },
        isSelectMonth(month){
            return this.$route.params.month == this.$moment(month + 1, "M").format("MMMM") ? "selected-month" : null
        },
        setYear(){
            this.$store.dispatch("changedevotionalYear", this.year)
        }
    },
    computed: {
        years(){
            let currentYear = dayjs().year() + 5
            let startYear = 2019
            let years = []
            while (currentYear >= startYear) {
                let year = currentYear--
                years.push(year)
            }
            return years;
        }
    },
}
</script>
